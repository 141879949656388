@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  ul.dropdown-menu {
    position: static;
    top: initial;
    left: initial;
    z-index: initial;
    min-width: 0;
    font-size: inherit;
    color: initial;
    text-align: initial;
    background-color: transparent;
    background-clip: initial;
    border: none;
    border-radius: 0;
  }
  ul.dropdown-menu li {
    display: flex;
  }
  ul.dropdown-menu li:hover .sub-menu {
    display: block;
  }
  ul.dropdown-menu .sub-menu {
    @apply hidden border border-[#eee] border-solid;
  }

  /* swiper css */
  .swiper-button-prev,
  .swiper-button-next {
    @apply rounded-full bg-[rgba(0,0,0,0.5)] !w-6 !h-6 sm:!w-9 sm:!h-9;
  }
  .swiper-button-prev:after,
  .swiper-button-next::after {
    @apply !text-xs sm:!text-sm md:!text-base lg:!text-xl text-white font-bold;
  }

  /* form */
  form .form-field-error .error-message {
    @apply text-sm text-red-600 flex items-center my-1;
  }
  form .form-field-error .error-message::before {
    content: "";
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAnRJREFUaEPdWltSwzAMTE8GnAw4GXAyQJ2os1UkWy93Js1POyWRdlcr27G5bCe/Lgvwv0JM/P69/86fLak7CDDI923bEPAIIJH4+b/ho8qiQoDARkBbWEtkMgRmwNEipDJfL/sXq0qf+99DVYkS+DJsQqAJgNffDJIqKC+K4ybhJWCpHkpmeIjASiJuQTwECDwpj5c7QaBJNSJTgWYEUkEDoOWtWr63kTVHBKTyK1TXuGoVN0mMCMiGnVXLAkOA3E0JQTA/iUckDpcFSpZyWEbDMi4AA7tpDjiQ0AhI8NNGUkCEbDAgMRVyRiADnvBoBDIWpFjDSsqgknE2KSXGWFkhNDHu7CwBIttK0sJIqj6KYtw1tCTwC49X1O8mQPEQ260KCLKr5CvAy15QCaywDy9BIgs9SwAcGG42wgp02wcrmplHNCIHjExAZVf0wgpLokuuojwNgRVqrYhpVmBFsofEZAs9JFmxp9TZ/Ww9cBD6bASedxSSa42OddCKvjInMnOtUWi8bgLqihSV7p6NVxK4LfUtAiR81UbdBNS12soXmk4C7hca+S5bWUV2EkD1794UNZtUt0O477sIDONoBGQVsu/GHQSmWzxWo8pduYyVUIiMCC4hvVuL5tbeZJ4gBelZ77kBhkPfh7cWrc2pTCWic6F2FpHa3LVIZOzgJRHezvdMVuGgXrRwn3UCNK24hwBXQjuR7KiGJpD7LMJLQBvbUejoCePopDM0YEQJ8GsdfWonjPQ7jzjaEStXU3OZW3V8OEMAq0Fnv97Teas1UsA5WIUAAiIfR8gwaKxYovfrS2YtKVdE+0ePMmCZsKsCKfU6HvoD2EPdMYIaXY8AAAAASUVORK5CYII=");
    @apply text-sm text-red-600 rounded-full mr-1  w-4 h-4 bg-contain bg-no-repeat;
  }
  form .form-field-error input,
  form .form-field-error textarea {
    @apply ring-red-500;
  }

  input::-webkit-search-cancel-button {
    -webkit-appearance: none;
    @apply bg-slate-50 border w-5 h-5 bg-[url('static/images/close.svg')] bg-contain cursor-pointer;
  }

  div:has(.header-sticky) {
    @apply sticky top-0 bg-white z-50; 
  }
}
